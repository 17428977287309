.landing-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 2%;
    box-sizing: border-box;
}

.card {
    width: 60%;
    height: 85%;
    border: 0.1em solid #000;
    border-radius: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 5%;
    overflow: hidden;
    position: relative;
}

.headline {
    font-weight: bold;
    color: black;
    line-height: 1.2;
    margin-top: 2rem;
    width: 100%;
    font-size: clamp(1.2em, 3vw, 3.5em);
    text-align: center;
    position: relative;
    z-index: 11;
}


.logo-wrapper {
    margin-top: clamp(1rem, 5vh, 3rem);
    width: 50%;
    aspect-ratio: 1 / 1;
    max-height: 100%;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
}

.outer-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: spin 16s linear infinite;
    position: relative;
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 50%;
    z-index: 10;
    margin-bottom: 1rem;
}

.top-right {
    position: absolute;
    top: clamp(1rem, 2vw, 3rem);
    right: 3rem;
    display: flex;
    align-items: center;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.icon {
    width: 20%;
    height: auto;
    color: black;
}

.signup-button {
    background: black;
    color: white;
    padding: 4% 15%;
    border-radius: 100em;
    font-weight: 600;
    font-size: clamp(0.5rem, 0.7vw, 1rem);
    white-space: nowrap;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
}

/* Spin animation for outer-circle */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Responsive (still optional, but based on card % not rem or px) */
@media (max-width: 768px) {
    .card {
        width: 90%;
    }

    .logo-wrapper {
        width: 70%;
    }

    .icon {
        width: 10%;
    }

    .signup-button {
        font-size: 5.5%;
    }
}

@media (max-width: 480px) {
    .logo-wrapper {
        width: 80%;
    }

    .icon {
        width: 12%;
    }

    .signup-button {
        font-size: 5%;
    }
}